import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { ConfigProvider } from 'antd';
import config from '../config';
import { setupStore } from './store';
import ScrollToTop from './components/ScrollTopRoute';
import Router from './router';

axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers['x-ulex-static'] = 'admin';

const store = setupStore();

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#000000',
        },
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
