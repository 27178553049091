import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { signIn, verify, initialization } from './actions';
import { IAdmin, AdminState } from '../../../types/Admin';

const initialState: AdminState = {
  admin: null,

  isLoading: false,
  error: '',
};

export const adminModule = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateAdmin: (state, action) => {
      if (state.admin) {
        state.admin = action.payload;
      }
    },
  },
  extraReducers: {
    // init
    [initialization.fulfilled.type]: (state, action: PayloadAction<IAdmin>) => {
      state.admin = action.payload;
      state.isLoading = false;
    },
    [initialization.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initialization.rejected.type]: (state) => {
      state.isLoading = false;
    },
    // signIn
    [signIn.fulfilled.type]: (state) => {
      state.error = '';
      state.isLoading = false;
    },
    [signIn.pending.type]: (state) => {
      state.isLoading = true;
    },
    [signIn.rejected.type]: (state, action: PayloadAction<string>) => {
      message.open({
        type: 'error',
        content: action.payload,
        duration: 3,
      });
      state.isLoading = false;
    },
    // verify
    [verify.fulfilled.type]: (state, action: PayloadAction<IAdmin>) => {
      state.error = '';
      state.admin = action.payload;
      state.isLoading = false;
    },
    [verify.pending.type]: (state) => {
      state.isLoading = true;
    },
    [verify.rejected.type]: (state, action: PayloadAction<string>) => {
      message.open({
        type: 'error',
        content: action.payload,
        duration: 3,
      });
      state.isLoading = false;
    },
  },
});

const { updateAdmin } = adminModule.actions;

export { initialization, signIn, verify, updateAdmin };

export default adminModule.reducer;
