import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CaseState, CaseStatus, ICase } from '../../../types/Dashboard';
import { getCases, updateCases, archiveCases } from './actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const seeds = [
  {
    _id: '1',
    status: CaseStatus.CREATED,
    type: 'Порушення швидкісного режиму',
    createdAt: '11.12.2022 о 10:30',
    client: {
      _id: 'client-1',
      data: {
        firstName: 'Чернюк',
        lastName: 'Владислав',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },
  {
    _id: '2',
    status: CaseStatus.CREATED,
    type: 'Порушення швидкісного режиму',
    createdAt: '11.12.2022 о 11:24',
    client: {
      _id: 'client-2',
      data: {
        firstName: 'Гнатченко',
        lastName: 'Влада',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },
  {
    _id: '3',
    status: CaseStatus.CREATED,
    type: 'Порушення правил обгону',
    createdAt: '12.12.2022 о 13:30',
    client: {
      _id: 'client-3',
      data: {
        firstName: 'Твердохліб',
        lastName: 'Сергій',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },

  {
    _id: '4',
    status: CaseStatus.RETURNED,
    type: 'Порушення швидкісного режиму',
    createdAt: '01.12.2022 о 9:30',
    client: {
      _id: 'client-4',
      data: {
        firstName: 'Цицима',
        lastName: 'Каріна',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },

  {
    _id: '5',
    status: CaseStatus.RETURNED,
    type: 'Порушення швидкісного режиму',
    createdAt: '01.12.2022 о 14:25',
    client: {
      _id: 'client-5',
      data: {
        firstName: 'Головатий',
        lastName: 'Кирило',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },

  {
    _id: '6',
    status: CaseStatus.RETURNED,
    type: 'Порушення правил обгону',
    createdAt: '30.11.2022 о 15:46',
    client: {
      _id: 'client-6',
      data: {
        firstName: 'Твердохліб',
        lastName: 'Сергій',
        birthday: '08.07.1998',
      },
    },
    manager: {
      name: 'Анастасія Петраковська',
    },
  },
];

const initialState: CaseState = {
  cases: [],
  search: '',
  selectedCases: [],
  filteredCases: [],

  isSelected: false,
  isNotFound: false,
  isFound: false,

  isLoading: false,
  error: '',
};

export const caseModule = createSlice({
  name: 'case',
  initialState,
  reducers: {
    setCases: (state, action: PayloadAction<ICase[]>) => {
      const updatedCases = action.payload;
      state.cases = updatedCases;
      const filteredCases = updatedCases.filter((el) =>
        `${el.client.data?.firstName} ${el.client.data?.lastName}`
          .toLowerCase()
          .includes(state.search.toLowerCase()),
      );
      state.isNotFound = !!state.search && !filteredCases.length;
      state.isFound = !!state.search && !!filteredCases.length;
      state.filteredCases = filteredCases;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      const filteredCases = state.cases.filter((el) =>
        `${el.client.data.firstName} ${el.client.data.lastName}`
          .toLowerCase()
          .includes(action.payload.toLowerCase()),
      );
      state.isNotFound = !!action.payload && !filteredCases.length;
      state.isFound = !!action.payload && !!filteredCases.length;
      state.search = action.payload;
      state.filteredCases = filteredCases;
    },
    selectCases: (state, action) => {
      state.selectedCases = action.payload;
    },
    toggleSelected: (state) => {
      const newState = !state.isSelected;

      if (!newState) {
        state.selectedCases = [];
      }

      state.isSelected = newState;
    },
  },
  extraReducers: {
    [getCases.fulfilled.type]: (state, action: PayloadAction<ICase[]>) => {
      state.cases = action.payload;
      state.isLoading = false;
    },
    [getCases.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getCases.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [updateCases.fulfilled.type]: (state, action: PayloadAction<ICase>) => {
      state.cases = state.cases.map((el) => {
        if (el._id === action.payload._id) {
          return action.payload;
        }

        return el;
      });
    },
    [archiveCases.fulfilled.type]: (state, action: PayloadAction<ICase[]>) => {
      state.isSelected = false;
      state.selectedCases = [];
      state.cases = action.payload;
    },
  },
});

const { setCases, setSearch, selectCases, toggleSelected } = caseModule.actions;

export { setCases, setSearch, selectCases, toggleSelected };

export default caseModule.reducer;
