import React, { FC } from 'react';
import { Outlet } from 'react-router';
import './style.scss';
import Header from '../Header';

const Layout: FC = () => {
  return (
    <div className="main-layout">
      <Header />
      <div className="main-layout__content">
        <Outlet />
      </div>
      <div className="main-layout__footer">Улекс 2022-2024. Всі права захищені.</div>
    </div>
  );
};

export default Layout;
