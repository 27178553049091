import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import config from '../../config';
import adminModule from './modules/Admin';
import caseModule from './modules/Dashboard';

const rootReducer = combineReducers({
  admin: adminModule,
  case: caseModule,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        serializableCheck: false,
      });

      if (config.debug) {
        console.log('logger');
        // @ts-ignore
        middleware = middleware.concat(logger);
      }
      return middleware;
    },
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
