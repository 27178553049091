import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { initialization } from '../store/modules/Admin';
import { Loader } from '../components/Loading';
import { useAppSelector, useAppDispatch } from '../hooks/redux';

const SingOutGuard: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { admin } = useAppSelector((state) => state.admin);

  useEffect(() => {
    dispatch(initialization())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  if (isLoading) return <Loader />;

  if (admin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default SingOutGuard;
