import { IAdmin } from './Admin';

export enum CaseStatus {
  CREATED = 'created',
  SUBMITTED = 'submitted',
  RETURNED = 'returned',
  ARCHIVED = 'archived',
}

export interface IClient {
  _id: string;
  email?: string;
  phone?: number;
  data: {
    firstName: string;
    lastName: string;
    middleName?: string;
    identCode?: string;
    phoneNumber?: string;
    birthday?: string;
    passportID?: string;
    city?: string;
    street?: string;
    index?: string;
    house?: string;
  };
  cars?: {
    brand: string;
    model: string;
    number: string;
    owner: boolean;
  }[];
}

export interface IAnswer {
  questionId: string;
  value: string;
  questions?: string[];
}

export interface ICase {
  _id: string;
  client: IClient;
  manager: IAdmin;
  answers?: IAnswer[];
  type: string;
  status: CaseStatus;
  createdAt: string;
  messageReturned: string;
}

export interface CaseState {
  cases: ICase[];
  search: string;
  selectedCases: string[];
  filteredCases: ICase[];

  isSelected: boolean;
  isNotFound: boolean;
  isFound: boolean;

  isLoading: boolean;
  error: string;
}
