import { createAsyncThunk } from '@reduxjs/toolkit';
import AdminService from '../../../services/admin.service';

export const initialization = createAsyncThunk('user/Init', async (_, thunkAPI) => {
  try {
    return await AdminService.initialization();
  } catch (error: any) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue('Не вірні данні!');
    }

    return thunkAPI.rejectWithValue('Помилка Сервера!');
  }
});

export const signIn = createAsyncThunk(
  'user/SignIn',
  async (params: { email: string; password: string }, thunkAPI) => {
    try {
      return await AdminService.signIn(params);
    } catch (error: any) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue('Не вірні данні!');
      }

      return thunkAPI.rejectWithValue('Помилка Сервера!');
    }
  },
);

export const verify = createAsyncThunk(
  'user/SignVerify',
  async (params: { code: string; admin: string }, thunkAPI) => {
    try {
      return await AdminService.verify(params);
    } catch (error: any) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue('Не вірні данні!');
      }

      return thunkAPI.rejectWithValue('Код невірний');
    }
  },
);
