import React from 'react';
import './style.scss';
import { Avatar } from 'antd';
import { AvatarSize } from 'antd/es/avatar/SizeContext';

interface IProps {
  src: string;
  size?: AvatarSize;
  name: string;
}

const AppAvatar = ({ src, size = 'large', name }: IProps) => {
  return (
    <Avatar src={src || 'http://httpstat.us/404'} className="ulex-avatar" size={size}>
      {(name[0] || '').toUpperCase()}
    </Avatar>
  );
};

export default AppAvatar;
