import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { useLocation } from 'react-router-dom';
import AuthGuard from './AuthGuard';
import SingOutGuard from './SingOutGuard';
import { Loader } from '../components/Loading';
import MainLayout from '../components/Layout';
import { signIn, profile, cases, posts } from './constants';

const titles = {
  '/': 'Головна',
  [signIn]: 'Авторизація',
  [profile]: 'Профіль',
  [cases]: 'Заяви',
  [posts]: 'Ulex Wiki',
};

const Dashboard = lazy(() => import('../views/Dashboard'));
const SignUp = lazy(() => import('../views/SignUp'));
const ErrorPage = lazy(() => import('../views/ErrorPage'));
const ProfilePage = lazy(() => import('../views/Profile'));
const CasesPage = lazy(() => import('../views/Cases'));
const PostsPage = lazy(() => import('../views/Posts'));

const Router = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = (titles as any)[location.pathname]
      ? `ULEX - ${(titles as any)[location.pathname]}`
      : 'ULEX';
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="*" element={<ErrorPage />} />

        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />
          </Route>
        </Route>

        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route path={cases} element={<CasesPage />} />
          </Route>
        </Route>

        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route path={posts} element={<PostsPage />} />
          </Route>
        </Route>

        <Route element={<AuthGuard />}>
          <Route element={<MainLayout />}>
            <Route path={profile} element={<ProfilePage />} />
          </Route>
        </Route>

        <Route element={<SingOutGuard />}>
          <Route path={signIn} element={<SignUp />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
