import axios from 'axios';

const CaseService = {
  async get() {
    const response = await axios.get('/api/v1/case/dashboard');
    return response.data;
  },

  async update(body: any) {
    const response = await axios.patch(`/api/v1/case/${body._id}`, body);
    return response.data;
  },

  async archive(cases: string[]) {
    const response = await axios.post('/api/v1/case/archive', {
      cases,
    });
    return response.data;
  },
};

export default CaseService;
