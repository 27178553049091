import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './style.scss';
import Avatar from '../ui/Avatar';

import { ReactComponent as MenuSVG } from '../../../assets/images/menu.svg';
import diya from '../../../assets/images/diya.svg';
import ulex from '../../../assets/images/ulex.svg';
import { useAppSelector } from '../../hooks/redux';
import { cases, profile, posts } from '../../router/constants';

function Header({ menu, setMenu }: any) {
  const { admin } = useAppSelector((state) => state.admin);

  return (
    <div className="wrapper-header-ulex">
      <div className="content-header-ulex">
        <div className="container-header">
          <Link to="/" className="item-header-logos">
            <img src={ulex} alt="" />
            <div className="logo-separator">на технологіях</div>
            <img src={diya} alt="" />
          </Link>
          <NavLink to="/" className="item-header-nav">
            Kanban Доска
          </NavLink>
          <NavLink to={cases} className="item-header-nav">
            Заявки
          </NavLink>
          <NavLink to={posts} className="item-header-nav">
            Статті
          </NavLink>
        </div>
        <div className="container-header">
          <div className="item-header-menu-mobile">
            <MenuSVG
              onClick={() => {
                setMenu(!menu);
              }}
            />
            {menu ? <div className="active-item-header" /> : null}
          </div>
          <div className="item-header-profile">
            <div>
              <span>Доброго дня!</span>
              <p>{admin?.name || ''}</p>
            </div>
            <Link to={profile}>
              <Avatar src={admin?.image || ''} name={admin?.name || ''} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
