import { createAsyncThunk } from '@reduxjs/toolkit';
import CaseService from '../../../services/case.service';

export const getCases = createAsyncThunk('case/Get', async (_, thunkAPI) => {
  try {
    return await CaseService.get();
  } catch (error: any) {
    console.log(error.message);
    return thunkAPI.rejectWithValue('Помилка Сервера!');
  }
});

export const updateCases = createAsyncThunk('case/Update', async (body: any, thunkAPI) => {
  try {
    return await CaseService.update(body);
  } catch (error: any) {
    console.log(error.message);
    return thunkAPI.rejectWithValue('Помилка Сервера!');
  }
});

export const archiveCases = createAsyncThunk('case/Archive', async (cases: string[], thunkAPI) => {
  try {
    return await CaseService.archive(cases);
  } catch (error: any) {
    console.log(error.message);
    return thunkAPI.rejectWithValue('Помилка Сервера!');
  }
});
