import axios from 'axios';

const AdminService = {
  async initialization() {
    const response = await axios.get('/api/v1/auth/profile');
    return response.data.user;
  },

  async signIn(body: any) {
    const response = await axios.post('/api/v1/auth/admin/login', body);
    return response.data.success;
  },

  async verify(body: any) {
    const response = await axios.post('/api/v1/auth/admin/loginVerify', body);
    return response.data.user;
  },

  async logout() {
    await axios.delete('/api/v1/auth/logout');
    return true;
  },
};

export default AdminService;
